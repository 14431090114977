.css-ur2jdm-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-ur2jdm-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiContainer-root.css-10ur324 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.css-10ur324 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1at24i0 {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}

.css-tmvorb {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}

.css-55luyf-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}

.css-4mhbuv-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}
.css-1i3a9kx-MuiStack-root {
  align-items: center;
}

.css-1pa8f5x {
  align-items: center;
}
